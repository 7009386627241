$blue-100: #CCE4F0 !default;
$blue-200: #5bbce0 !default;
$blue-300: #2699FB !default;
$blue-400: #0078b6 !default;
$blue-500: #043E6B !default;

$purple: #6477D7 !default;
$pink: #d7176f;
$red: #DA3236 !default;
$orange: #F59E0B !default;
$yellow: #F3C96C !default;
$green: #3EC075 !default;
$teal: #47d1b1 !default;
$dark-teal: #40767A !default;
$dark: #344453 !default;
$almost-dark: #2F5462 !default;
$cyan: #5ebcdd !default;
$cardinal: #A41A55;

$primary: #982B8F !default;
$success: $green !default;
$info: #006F97 !default;
$warning: #F16a29 !default;
$danger: $red !default;
$dark: $dark !default;

$white: #fff !default;
$gray-100: #F8F9FB !default;
$gray-200: #E1E7EC !default;
$gray-300: #F0F2F5 !default;
$gray-400: #CCD4DB !default;
$gray-500: #AEBECC !default;
$gray-600: #929FB0 !default;
$gray-700: #6E7A89 !default;
$gray-800: #404B59 !default;
$gray-900: rgba(17, 24, 39) !default;
$black: #000 !default;
$secondary: $gray-300 !default;
$light: $gray-100 !default;

$enable-shadows: true;
$enable-negative-margins: true;

$min-contrast-ratio: 4 !default;

// Body
$body-bg: #EFF1F2 !default;
$body-color: $gray-900 !default;
$body-tertiary-color: rgba($body-color, .5) !default;

// Components
$component-active-color: $white !default;
$component-active-bg: $primary !default;
$input-btn-focus-box-shadow: 0;
$input-color: $gray-800;
$input-disabled-bg: $gray-200 !default;
$dropdown-border-width: 0 !default;
$box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06) !default;
$tag-bg: $gray-400;

// Links
$link-color: $dark !default;
$link-decoration: none !default;
$link-hover-color: shade-color($link-color, 15%) !default;
$navbar-light-icon-color: $gray-600 !default;

// Fonts
$font-size-base: .875rem !default; // Assumes the browser default, typically `16px`
$font-family-sans-serif: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

// Forms
//$input-box-shadow: none; //inset 0 2px 4px 0 hsla(0, 0%, 0%, 0.08) !default;
$input-bg: $gray-100 !default;
$input-focus-bg: $white !default;
$form-check-input-bg: $white !default;
$form-check-input-border: 1px solid $gray-500 !default;
$input-placeholder-color: $gray-600 !default;
$input-disabled-bg: $gray-300 !important;
$legend-font-size: $font-size-base !important;

// Popovers
$popover-bg: $white;

// Buttons
$btn-color: $gray-700 !default;
$btn-hover-bg-shade-amount: 30% !default;
$btn-hover-bg-tint-amount: 30% !default;
$btn-link-color: $dark !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$btn-disabled-opacity: .35 !default;


// Navbar
$navbar-dark-toggler-border-color: transparent !default;
$navbar-light-toggler-border-color: transparent !default;
$navbar-nav-link-padding-x: .75em !default;
$nav-link-disabled-color: rgba(0, 0, 0, 0.3);

// Navs
$nav-tabs-link-hover-border-color: none !default;
$nav-tabs-border-color: $gray-400 !default;
$nav-tabs-link-active-bg: $white !default;
$nav-tabs-link-active-border-color: transparent !default;

// Modals
$modal-content-bg: $white !default;
$modal-content-border-width: 0 !default;
$modal-lg: 900px !default;
$modal-md: 800px !default;
$modal-sm: 450px !default;
$modal-backdrop-bg: #000;
$modal-backdrop-opacity: .8 !default;
$modal-fade-transform: none;
$modal-transition: .15s cubic-bezier(.4, 0, .2, 1) !default;
$modal-footer-bg: $gray-300;
$modal-content-box-shadow-xs: $box-shadow;
$modal-content-box-shadow-sm-up: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04) !default;

// Alerts
$alert-bg-level: -11 !default;
$alert-border-width: 0 !default;
$alert-border-level: -8 !default;

// Badge
$badge-font-weight: bold !default;

// Cards
$card-cap-padding-y: 1em;
$card-cap-bg: $gray-100;
$card-bg: $white !default;

// List groups
$list-group-bg: $white !default;

// Breadcrumb
$breadcrumb-padding-y: 1.5em !default;
$breadcrumb-padding-x: 2em !default;
$breadcrumb-divider: quote("⁄") !default;
$breadcrumb-divider-color: $gray-400 !default;
$breadcrumb-item-padding: .75em !default;
$breadcrumb-active-color: rgba($body-color, .5) !default;

// Tables
$table-bg: $white !default;

// Dropdowns
$dropdown-bg: $white !default;

// Accordion
$accordion-bg: $white !default;
$accordion-button-active-bg: $gray-200 !default;
body {
  font-family: $font-family-sans-serif;
  overflow-x: hidden;
  width: 100%;
}

a {
  white-space: nowrap !important;

  svg,
  object {
    pointer-events: none;
  }
}

p {
  a {
    color: $primary;
  }
}

.disabled {
  pointer-events: none;
}

.busy {
  .disabled-when-busy {
    @extend .disabled;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .d-none-busy {
    display: none !important;
  }

  .d-busy {
    z-index: $zindex-dropdown;
    display: inherit !important;
  }
}

.is-invalid {
  .needs-valid {
    @extend .disabled;
    pointer-events: none;
  }
}

.d-busy {
  display: none !important;
}

.toast-container {
  z-index: $zindex-toast;
}

.toast {
  min-width: 200px;
}

.alert {
  border-top-width: 4px;
}

pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
}

[data-toggle=virtual-popper] {
  &:hover,
  &:focus,
  &:active {
    outline: none !important;
  }
}

.btn {
  text-shadow: none !important;
}